body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #f1f1f1;
}

.NavigationBarLeft {
  padding-top: 19px;
}

.NavigationBarRight {
  padding-top: 17px;
}

.topDiv {
  /* border-style: solid;
  border-width: thin;
  background-color: black; */

  overflow: hidden;
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  z-index: 998;
}

.ProjectDescriptionSection {
  margin-left: 30px;
}

.Hamburger {
  color: black !important;
}

.FontSize {
  font-size: 22px;
}

.FontSizeGeneral {
  font-size: 16px;
  text-align: left;
}

.PFontSize {
  font-size: 18px;
}

.Font {
  font-family: 'Century Gothic', 'Century Gothic', CenturyGothic, AppleGothic, sans-serif;
}

@media only screen and (max-width: 600px) {
  .Font {
    font-family: 'Open Sans', sans-serif;
    font-size: 35px;
  }
}

.DefaultFont {
  font-family: 'Comic Sans', 'Comic Sans MS', 'Chalkboard', 'ChalkboardSE-Regular', sans-serif;
}

.PaddingTop {
  padding-top: 10px;
  margin-bottom: 0px !important;
}

.Padding {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
  font-size: 22px;
}

.PaddingPoppingStation {
  padding-top: 60px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .PaddingPoppingStation {
    padding: 0;
  }
}

.PaddingPoppingStationPhase1 {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .PaddingPoppingStationPhase1 {
    padding: 0;
  }
}

.DescriptionTopMargin {
  padding-top: 1000px;
}

.MintingBox {
  /* border-style: solid;
  border-width: medium; */
  margin: 0.2rem 0 1rem 0;
  padding-top: 27%;
  /* padding: 1em; */
  text-align: center;
  position: relative;
  min-height: 452px;
  z-index: 1;
}

.mintButton {
  padding-top: 10px !important;
}

main {
  flex: 1 0 auto;
}

.content {
  max-width: 1200px;
  min-width: 1200px;
  margin: auto;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .content {
    max-width: 1200px;
    min-width: 200px;
    margin: auto;
    background-color: white;
  }
}

.Section {
  background-color: #89cff0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.FreeSaleSection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.button {
  border: none;
  color: white;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-right: 17px;
  text-align: left;
  text-decoration: none;
  font-size: 16px;
  border-radius: 12px;
  min-width: 200px;
  border-radius: 12px;
  background-color: #90caf9;
  display: inline-block;
  vertical-align: bottom;
  min-width: 100px;
  line-height: 1.6;
}

.StickySections {
  top: 5em;
  position: sticky;
}

.StickySectionsPhase1 {
  top: 5em;
  padding-bottom: 100px;
  position: sticky;
}

.ImagePoppableLogo {
  width: 53%;
  margin-left: 25%;
}

.ImageCenter {
  margin: auto;
  width: 53%;
}

@media only screen and (max-width: 980px) {
  .ImagePoppableLogo {
    width: 60%;
  }
}

@media only screen and (max-width: 850px) {
  .ImagePoppableLogo {
    width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .ImagePoppableLogo {
    width: 50%;
    padding-left: 40px;
    margin-left: 0%;
  }
}
@media only screen and (max-width: 414px) {
  .ImagePoppableLogo {
    width: 70%;
  }
}

@media only screen and (max-width: 360px) {
  .ImagePoppableLogo {
    width: 80%;
  }
}

@media only screen and (max-width: 280px) {
  .ImagePoppableLogo {
    width: 100%;
  }
}

.mypoppables {
  /* border-style: solid;
  border-width: thin; */
  margin-top: 50px;
  margin-bottom: 100px;
}

.polaroid {
  margin-left: 20px;
  width: 60%;
  margin-bottom: 25px;
  vertical-align: 50%;
  margin-top: 37vh;
}

.image-container {
  text-align: center;
  padding: 10px 20px;
}

.divborder {
  border-style: solid;
  border-width: thin;
}

@media only screen and (max-width: 600px) {
  .ImageResize {
    width: 100%;
    position: absolute;
    top: 50%;
  }
}

@media only screen and (max-width: 600px) {
  .polaroid {
    margin-left: 20px;
    width: 80%;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 25px;
  }
}

/******************************** THE POPPABLE********************************** */
.TextPadding {
  padding-left: 5%;
  font-size: 22px;
}

.flex-container {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row nowrap;

  /* align-content: flex-start; */

  /* Then we define how is distributed the remaining space */
  justify-content: stretch;

  max-width: 800px;
  padding-left: 10%;

  /* height: 515px; */
}

.ElementHidden {
  display: none;
}

.child {
  margin: auto;
  height: 100%;
}

.ImageSelected {
  border: 1px solid #e91717;
}
.ImageUnSelected {
  border: 0px;
}

MyPoppableImageDiv {
  height: 100%;
  width: 200px;
}

.MyPoppableImage {
  max-width: 100%;
  height: auto;

  /* opacity: 0.2; */
}

.MyPoppablePassmage {
  max-width: 100%;
  height: auto;
  opacity: 0.1;
}

.MyPoppableStationStairsImage {
  max-width: 55%;
  height: auto;
}

.MyPoppableSelected {
  border: 1px solid red;
}

.MyPoppableStationPopButtonImageHover img:hover {
  opacity: 0.8;
}

.buttonSelected {
  border: 2px solid #f9ddd2 !important;
}

.MyPoppableImageContainer {
  border-style: solid;
  border-width: medium;
  border-radius: 15px;
  padding-top: 8px;
  padding-left: 13% !important;
  padding-right: 1-px;
  padding-bottom: 5px;
  border-color: #676767;
  margin: 10px;
  min-width: 155px;
  width: 155px;
  max-height: 444px;
  height: 444px;
}

.MyPoppableImageContainerInside {
  overflow-x: hidden;
  overflow-y: auto;
  /* border-radius: 10px; */
  max-width: 100%;
  max-height: 375px;
  padding-right: 15px;
}

.MyPoppablePassesImageContainer {
  border-style: solid;
  border-width: medium;
  border-radius: 15px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 20px;
  padding-bottom: 5px;
  border-color: #676767;
  margin: 10px;
  max-width: 100%;
  max-height: 500px;
}

.MyPoppablePassesImageContainerInside {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 10px;
  max-width: 100%;
  max-height: 475px;
}

.my-poppables-images {
  padding: 10px;
}

/*********************************** ROAD MAP SECTION  ***************************/
.RoadMapSection {
  margin-top: 300px;
  scroll-margin-top: 35em;
}

.RoadMapSize {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}
.RoadMapContainer {
  position: relative;
  text-align: center;
  max-width: 1200px;
}

.RoadMapBubbleImage {
  max-width: 80%;
  height: auto;
  margin-top: 1vh;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 13%;
  left: 7%;
  /* border-style: solid;
  border-width: thin; */
  max-width: 38%;
  color: white;
  vertical-align: 50%;
}

/* Top right text */
.top-right {
  /* border-style: solid;
  border-width: thin; */
  position: absolute;
  top: 29%;
  right: 7%;
  max-width: 38%;
  color: white;
  vertical-align: 50%;
}

.MyPoppableStationPopButtonImage {
  max-width: 55%;
  height: auto;
}

/* Top left text */
.middle-left {
  position: absolute;
  top: 46%;
  left: 3%;
  /* border-style: solid;
  border-width: thin; */
  max-width: 46%;
  color: white;
  vertical-align: 50%;
}

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}

/* Bottom right text */
.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.h {
  font-weight: bold;
}

.scrollOffset {
  scroll-margin-top: 1100px;
}

@media only screen and (max-width: 600px) {
  .scrollOffset {
    scroll-margin-top: 300px;
  }
}

.center-elements {
  margin-top: 9%;
  padding-left: 0px;
}

.text-align-right {
  text-align: right;
}

.LegalSection {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(8px + 2vmin);
}

.LegalContent {
  padding-top: 3%;
  max-width: 1200px;
  margin-left: 5%;
  margin-right: 5%;
}

.MyPoppableStationStairsImageDivSize {
  margin-left: 60px;
  height: 100%;
  min-width: 190px;
  width: 190px;
}

@media only screen and (max-width: 600px) {
  .ImageResize {
    width: 100%;
    position: absolute;
    top: 50%;
  }
}

.CongratulationsMessage {
  color: red;
}

.Message {
  padding-left: 46%;
}

.Loader {
  padding-left: 15%;
  min-height: 25px;
  height: 25px;
}

.MyPoppablePasseslSection {
  /* min-height: 5vh; */
  display: flex;
  flex-direction: column;
  /* font-size: calc(2vmin); */
}

@media only screen and (max-width: 600px) {
  .MyPoppablePasseslSection {
    display: flex;
    flex-direction: column;
    font-size: calc(8px + 2vmin);
  }
}

.MyPoppablePassesContent {
  padding-top: 1%;
  max-width: 1200px;
}

.MyPassesContent {
  max-width: 1200px;
  min-height: 100vh;
  margin: auto;
  background-color: white;
}

.MyPassesTitle {
  padding-left: 30%;
}

@media only screen and (max-width: 600px) {
  .MyPassesTitle {
    font-family: 'Open Sans', sans-serif;
    font-size: 25px !important;
    padding-top: 17px;
    /* padding-left: 8%; */
  }
}

@media only screen and (max-width: 850px) {
  .MyPassesTitle {
    font-family: 'Open Sans', sans-serif;
    padding-top: 17px;
    font-size: 25px !important;
    padding-left: 30%;
  }
}

.MyPoppableStationImage {
  max-width: 70%;
  height: auto;
}

.MyPoppableStationImageDivSize {
  height: 100%;
  min-width: 450px;
  width: 450px;
}

/************MOBILE POPPING STATION********************/
.flex-container-mobile {
  /* We first create a flex layout context */
  display: flex;

  /* Then we define the flow direction 
     and if we allow the items to wrap 
   * Remember this is the same as:
   * flex-direction: row;
   * flex-wrap: wrap;
   */
  flex-flow: row wrap;

  /* align-content: flex-start; */

  /* Then we define how is distributed the remaining space */
  justify-content: stretch;

  /* max-width: 500px; */
  padding-left: 3%;

  /* height: 515px; */
}

.MyPoppableImageContainer-mobile {
  border-style: solid;
  border-width: medium;
  border-radius: 15px;
  border-color: #676767;
  height: 30%;
  width: 97%;
}

.MyPoppableImageContainerInside-mobile {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  width: 98%;
}

.MyPoppableImage-mobile {
  width: 25%;
  height: auto;
  margin: 2%;
}

.MyPoppableStationImageDivSize-mobile {
  height: 100%;
  width: 100%;
}

.MyPoppableStationImage-mobile {
  margin: auto;
  height: auto;
  margin-top: 2%;
  width: 97%;
}

.MyPoppableStationStairsImage-mobile {
  margin-top: 3%;
  margin-left: 38%;
  max-width: 20%;
  height: auto;
}

.MyPoppableStationStairsImageDivSize-mobile {
  margin-left: 35%;
  height: 100%;
  min-width: 190px;
  width: 190px;
}

.MyPoppableStationPopButtonImageHover-mobile {
  height: 100%;
  width: 200px;
}

.MyPoppableStationPopButtonImage-mobile {
  max-width: 60%;
  height: auto;
}

.Loader-mobile {
  padding-left: 15%;
  min-height: 25px;
  height: 25px;
}
.RoadMapSection-mobile {
  margin-top: 1%;
}

.Message-mobile {
  padding-left: 40%;
}

.Player {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.GiftMintButton {
  padding-top: 20% !important;
}

.DetourContainer {
  position: absolute;
  top: 86%;
  left: 84.5%;
  height: 4%;
}

@media only screen and (max-width: 540px) {
  .DetourContainer {
    position: absolute;
    top: 88%;
    left: 67%;
    height: 3%;
  }
}

@media only screen and (max-width: 428px) {
  .DetourContainer {
    position: absolute;
    top: 88%;
    left: 67%;
    height: 3%;
  }
}

@media only screen and (max-width: 414px) {
  .DetourContainer {
    position: absolute;
    top: 88%;
    left: 67%;
    height: 3%;
  }
}

@media only screen and (max-width: 393px) {
  .DetourContainer {
    position: absolute;
    top: 88%;
    left: 66%;
    height: 3%;
  }
}

@media only screen and (max-width: 375px) {
  .DetourContainer {
    position: absolute;
    top: 88%;
    left: 65%;
    height: 3%;
  }
}

@media only screen and (max-width: 360px) {
  .DetourContainer {
    position: absolute;
    top: 86%;
    left: 78%;
    height: 3%;
  }
}

.DetourImage {
  width: auto;
  height: 100%;
  animation: pulse 1.5s linear infinite;
}

.ImageLabel {
  color: #fa7f4c;
  margin-top: -135%;
  padding-left: 15px;
  width: 95px;
}

@media only screen and (max-width: 540px) {
  .ImageLabel {
    color: #fa7f4c;
    margin-top: -100%;
    padding-right: 5px;
    width: 100px;
  }
}

@media only screen and (max-width: 428px) {
  .ImageLabel {
    color: #fa7f4c;
    margin-top: -110%;
    padding-left: 1px;
    width: 80px;
  }
}

@media only screen and (max-width: 414px) {
  .ImageLabel {
    color: #fa7f4c;
    margin-top: -105%;
    padding-left: 0px;
    width: 80px;
  }
}

@media only screen and (max-width: 375px) {
  .ImageLabel {
    color: #fa7f4c;
    margin-top: -105%;
    padding-left: 2px;
    width: 80px;
  }
}

@media only screen and (max-width: 360px) {
  .ImageLabel {
    color: #fa7f4c;
    margin-top: -90%;
    padding-left: 2px;
    width: 80px;
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.MainPoppableScene {
  position: relative;
  min-width: 1200px;
}

@media only screen and (max-width: 600px) {
  .MainPoppableScene {
    position: relative;
    min-width: 300px;
  }
}

.GifImage {
  position: absolute;
  top: 23.2%;
  left: 15.8%;
  width: 26%;

  min-width: 307px;
}

@media only screen and (max-width: 820px) {
  .GifImage {
    position: absolute;
    top: 25%;
    left: 11%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 540px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 21.5%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 480px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 14%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 414px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 12.8%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 393px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 10.6%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 375px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 8.8%;
    width: 26%;

    min-width: 307px;
  }
}

@media only screen and (max-width: 360px) {
  .GifImage {
    position: absolute;
    top: 13%;
    left: 7.5%;
    width: 26%;

    min-width: 307px;
  }
}

.MintingBoxContainer {
  position: absolute;
  padding: 10px;
  top: 23.2%;
  left: 58.2%;
  width: 26%;
  height: 24.9%;

  min-width: 307px;
  min-height: 435px;

  border-style: solid;
  border-width: thin;
  background-color: white;

  text-align: center;
}

@media only screen and (max-width: 540px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 36%;
    left: 21.5%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

@media only screen and (max-width: 480px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 44%;
    left: 14%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

@media only screen and (max-width: 414px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 41.5%;
    left: 12.8%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

@media only screen and (max-width: 393px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 43%;
    left: 10.6%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 44%;
    left: 8.8%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

@media only screen and (max-width: 360px) {
  .MintingBoxContainer {
    position: absolute;
    padding: 10px;
    top: 45%;
    left: 7.5%;
    width: 26%;
    height: 24%;

    min-width: 307px;
    min-height: 424px;

    border-style: solid;
    border-width: thin;
    background-color: white;

    text-align: center;
  }
}

.PoppableLeftSection {
  padding-top: 30px;
  font-weight: bold;
  line-height: 0.5;
}

.PoppableLeftSectionDistruction {
  font-weight: bold;
  color: red;
  line-height: 1.5;
}

.BoldText {
  font-weight: bold;
  line-height: 0.5;
}

.guideline {
  max-width: 1100px;
  min-width: 1100px;
  margin: auto;
}

.ListItems {
  margin-left: 5%;
}

.Ul{
  list-style-type: disc !important;
}

.H2 {
  color: #555555;
}

.GuideImage {
  width: 100%;
  margin-top: 3%;
  margin-bottom: 3%;
}